<template>
  <v-card class="team-role-edit">
    <v-toolbar flat dark color="primary" class="rounded-t-lg">
      <v-avatar size="32" color="white" class="mr-3">
        <span class="primary--text font-weight-bold">{{ initials }}</span>
      </v-avatar>
      <v-toolbar-title class="text-h6">{{ userName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="pt-6">
      <v-form ref="form" v-model="valid">
        <!-- Team Selection -->
        <v-select
          v-model="formData.team"
          :items="teams"
          label="Équipe"
          outlined
          dense
          prepend-inner-icon="mdi-account-group"
          :rules="[(v) => !!v || 'Sélection d\'équipe requise']"
          class="mb-4"
        ></v-select>

        <!-- Planning Section -->
        <v-card outlined class="mb-4">
          <v-card-title class="py-2 primary--text">
            <v-icon left color="primary">mdi-calendar-clock</v-icon>
            Planning
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <div class="text-subtitle-2 mb-2 grey--text text--darken-1">Portée du planning</div>
                <v-radio-group v-model="formData.roles.planning.scope" class="mt-0" mandatory>
                  <v-radio label="Tous les plannings" value="all" color="deep-purple"></v-radio>
                  <v-radio label="Planning d'équipe" value="team" color="indigo"></v-radio>
                  <v-radio label="Planning personnel" value="self" color="blue"></v-radio>
                </v-radio-group>
              </v-col>
              
              <v-col cols="12" md="6">
                <div class="text-subtitle-2 mb-2 grey--text text--darken-1">Déplacement des tâches</div>
                <v-radio-group v-model="formData.roles.planning.dragDrop" class="mt-0" mandatory>
                  <v-radio label="Toutes les tâches" value="all" color="deep-purple"></v-radio>
                  <v-radio label="Tâches d'équipe" value="team" color="indigo"></v-radio>
                  <v-radio label="Tâches personnelles" value="self" color="blue"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <!-- Tasks Section -->
        <v-card outlined>
          <v-card-title class="py-2 success--text">
            <v-icon left color="success">mdi-checkbox-marked-circle</v-icon>
            Gestion des tâches
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-list dense class="permissions-list">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Créer des tâches</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.create" color="primary" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Voir toutes les tâches</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.viewAllTeams" color="primary" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Voir tâches d'équipe</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.viewTeamTasks" color="info" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
              
              <v-col cols="12" md="6">
                <v-list dense class="permissions-list">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Modifier tâches personnelles</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.writeOwnTasks" color="success" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Modifier tâches d'équipe</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.writeTeamTasks" color="warning" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2">Modifier autres tâches</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-switch v-model="formData.roles.tasks.editOthersTasks" color="error" inset></v-switch>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')">Annuler</v-btn>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="!valid"
        @click="save"
      >
        <v-icon left>mdi-content-save</v-icon>
        Enregistrer
      </v-btn>
    </v-card-actions>

    <!-- Loading Overlay -->
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import DroitService from "@/Services/SupportVision/DroitService";

export default {
  name: "TeamRoleEdit",

  props: {
    teamRole: {
      type: Object,
      required: true
    },
    userName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      valid: true,
      loading: false,
      teams: ["Encadrement", "Projet", "Développeur"],
      formData: {
        userId: null,
        team: null,
        roles: {
          planning: {
            scope: "self",
            dragDrop: "self"
          },
          tasks: {
            create: false,
            viewAllTeams: false,
            viewTeamTasks: false,
            writeOwnTasks: false,
            writeTeamTasks: false,
            editOthersTasks: false
          }
        }
      }
    };
  },

  watch: {
  teamRole: {
    immediate: true,
    handler(newVal) {
      if (newVal) {
        this.initForm();
      }
    }
  }
},

  computed: {
    initials() {
      return this.userName
        .split(" ")
        .map(n => n[0])
        .join("")
        .toUpperCase();
    }
  },

  methods: {
    initForm() {
      if (!this.teamRole) return;
      
      this.formData = {
        userId: this.teamRole.userId,
        team: this.teamRole.team,
        roles: {
          planning: {
            scope: this.teamRole.roles?.planning?.scope || "self",
            dragDrop: this.teamRole.roles?.planning?.dragDrop || "self"
          },
          tasks: {
            create: this.teamRole.roles?.tasks?.create || false,
            viewAllTeams: this.teamRole.roles?.tasks?.viewAllTeams || false,
            viewTeamTasks: this.teamRole.roles?.tasks?.viewTeamTasks || false,
            writeOwnTasks: this.teamRole.roles?.tasks?.writeOwnTasks || false,
            writeTeamTasks: this.teamRole.roles?.tasks?.writeTeamTasks || false,
            editOthersTasks: this.teamRole.roles?.tasks?.editOthersTasks || false
          }
        }
      };
    },

    async save() {
      if (!this.$refs.form.validate()) return;
      
      try {
        this.loading = true;
        await DroitService.editTeamAndRole(this.teamRole._id, {
          teamRole: {
            userId: this.formData.userId,
            team: this.formData.team,
            roles: this.formData.roles
          }
        });

        this.$emit("updated");
        this.$nSuccess("Configuration mise à jour avec succès");
        this.$emit("close");
      } catch (error) {
        console.error("Erreur lors de la mise à jour:", error);
        this.$nError("Erreur lors de la mise à jour des droits");
      } finally {
        this.loading = false;
      }
    }
  },

  created() {
    this.initForm();
  }
};
</script>

<style scoped>
.team-role-edit {
  max-height: 90vh;
  overflow-y: auto;
}

.permissions-list {
  background-color: transparent !important;
}

.v-list-item {
  padding: 0;
  min-height: 40px !important;
}

.v-radio {
  margin-top: 2px;
  margin-bottom: 2px;
}

.v-list-item__title.subtitle-2 {
  font-size: 0.875rem !important;
}

/* Custom scrollbar */
.team-role-edit::-webkit-scrollbar {
  width: 8px;
}

.team-role-edit::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.team-role-edit::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.team-role-edit::-webkit-scrollbar-thumb:hover {
  background: #666;
}

/* Transitions */
.v-enter-active, .v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter, .v-leave-to {
  opacity: 0;
}
</style>