<template>
  <v-container fluid>
    <!-- Toolbar -->
    <v-toolbar rounded class="mb-2">
      <v-toolbar-title class="text-h5"
        >Gestion des Utilisateurs</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-menu offset-y :max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-if="isSuperAdmin">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list :min-width="250">
          <v-list-item @click="openModal">
            <v-list-item-title
              style="cursor: pointer"
              :class="
                !isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'
              "
            >
              Droits
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="basesParams.length > 0"
            @click="openModalListParametrage"
          >
            <v-list-item-title
              style="cursor: pointer"
              :class="
                !isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'
              "
            >
              Liste paramétrages
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="openModalParametrage">
            <v-list-item-title
              style="cursor: pointer"
              :class="
                !isSuperAdmin ? 'grey--text' : 'purple--text text--darken-3'
              "
            >
              Paramétrages
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <!-- Alert -->
    <!-- <v-col cols="4" v-if="droit.length === 0">
      <v-alert
        type="warning"
        prominent
        border="left"
        elevation="2"
        colored-border
      >
        Aucun droit n'a été créé ou vous n'avez pas accès à cette interface.
      </v-alert>
    </v-col> -->

    <!-- Quick Actions -->
    <div class="d-flex align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            small
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="dialog = true"
            :disabled="!isSuperAdmin"
          >
            <v-icon left>mdi-account-multiple-plus</v-icon>
            Droits d'accès aux menus
          </v-btn>
        </template>
        <span>Gérer les droits des utilisateurs</span>
      </v-tooltip>

      <!-- rightmenu -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            outlined
            small
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="openRightsMenu"
            :disabled="!isSuperAdmin"
          >
            <v-icon left>mdi-square-edit-outline</v-icon>
            Editer les droits
          </v-btn>
        </template>
        <span>Editer les droits</span>
      </v-tooltip>

      <v-tooltip bottom v-if="teamRoles.length > 0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="purple"
            outlined
            small
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="listeTeamRoleModal = true"
            :disabled="!isSuperAdmin"
          >
            <v-icon left>mdi-account-group-outline</v-icon>
            Équipes & Rôles
          </v-btn>
        </template>
        <span>Gérer les équipes et leurs rôles</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="basesParams.length > 0"
            color="deep-purple"
            outlined
            small
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="dialogListParametrage = true"
            :disabled="!isSuperAdmin"
          >
            <v-icon left>mdi-format-list-checks</v-icon>
            Liste paramétrages
          </v-btn>
          <v-btn
            v-else
            color="deep-purple"
            outlined
            small
            class="mr-2"
            v-bind="attrs"
            v-on="on"
            @click="dialogParametrage = true"
            :disabled="!isSuperAdmin"
          >
            <v-icon left>mdi-cog</v-icon>
            Paramétrages
          </v-btn>
        </template>
        <span>Gérer les paramétrages</span>
      </v-tooltip>
      <!-- Search -->
      <v-col cols="3" class="ml-auto">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher un utilisateur"
          single-line
          solo
          hide-details
          dense
          class="mx-4"
        ></v-text-field>
      </v-col>
    </div>

    <!-- Users Grid -->
    <v-row>
      <v-col
        v-for="user in sortedDroit"
        :key="user.id"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          class="mx-auto user-card"
          elevation="2"
          hover
          :class="{ 'cursor-pointer': isSuperAdmin }"
        >
          <div class="user-card-content pa-4">
            <!-- User Header -->
            <div class="d-flex align-center mb-4">
              <v-avatar color="primary" size="40" class="mr-4">
                <span class="white--text text-h6">
                  {{ getInitials(user.firstname, user.lastname) }}
                </span>
              </v-avatar>
              <div class="user-info">
                <div class="text-subtitle-1 font-weight-medium">
                  {{ user.firstname }} {{ user.lastname }}
                </div>
                <div class="text-caption grey--text">
                  {{ getUserTeam(user.id) }}
                </div>
              </div>

              <!-- Edit or Create Button -->
              <v-spacer></v-spacer>
              <!-- bouton d ajout a role equipe -->
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :color="
                      getUserTeamFromRoles(user.id) === 'Non assigné'
                        ? 'primary'
                        : 'warning'
                    "
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      getUserTeamFromRoles(user.id) === 'Non assigné'
                        ? openModalCreateTeam(user)
                        : openModalDeleteTeam(user)
                    "
                    :disabled="!isSuperAdmin"
                  >
                    <v-icon>{{
                      getUserTeamFromRoles(user.id) === "Non assigné"
                        ? "mdi-account-multiple-plus"
                        : "mdi-account-cog-outline"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  getUserTeamFromRoles(user.id) === "Non assigné"
                    ? "Ajouter à une équipe"
                    : "Modifier les droits de la ressource"
                }}</span>
              </v-tooltip>
            </div>

            <div class="mb-3">
              <v-row align="center" no-gutters>
                <v-col cols="12" sm="6">
                  <div class="text-caption grey--text mb-1">Droits :</div>
                  <div class="d-flex flex-wrap">
                    <template v-if="hasUserRights(user.id)">
                      <v-chip
                        v-for="right in getUserRights(user.id)"
                        :key="right"
                        x-small
                        class="mr-1 mb-1 text-uppercase"
                        :color="getRightColor(right)"
                        text-color="white"
                        label
                      >
                        {{ right }}
                      </v-chip>
                    </template>
                    <div v-else class="no-rights-text">
                      Aucun droit attribué
                    </div>
                  </div>
                </v-col>

                <v-col cols="12" sm="6" class="text-sm-right">
                  <div class="text-caption grey--text mb-1">Équipe :</div>
                  <div
                    class="d-flex flex-wrap"
                    :class="{ 'justify-end': $vuetify.breakpoint.smAndUp }"
                  >
                    <v-chip
                      x-small
                      class="mr-1 mb-1 text-uppercase"
                      color="deep-purple accent-4"
                      text-color="white"
                      label
                    >
                      {{ getUserTeamFromRoles(user.id) }}
                    </v-chip>
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- Menus with Expand -->
            <v-expand-transition>
              <div class="menu-section">
                <v-btn
                  text
                  block
                  small
                  @click.stop="toggleMenus(user)"
                  class="text-caption mb-2 d-flex align-center justify-space-between"
                >
                  <span>Menus autorisés</span>
                  <div class="d-flex align-center">
                    <v-chip
                      x-small
                      label
                      class="mr-2"
                      :color="getUserRights(user.id)[0] ? 'primary' : 'grey'"
                      text-color="white"
                    >
                      {{ getUserMenus(user.id).length }}
                    </v-chip>
                    <v-icon small>
                      {{
                        user.showMenus ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}
                    </v-icon>
                  </div>
                </v-btn>
                <div v-show="user.showMenus" class="menus-container">
                  <template v-if="getUserMenus(user.id).length">
                    <v-chip
                      v-for="menu in getUserMenus(user.id)"
                      :key="menu"
                      x-small
                      class="mr-1 mb-1"
                      color="primary"
                      text-color="white"
                      label
                    >
                      {{ menu }}
                    </v-chip>
                  </template>
                  <div v-else class="no-menus-text">
                    Aucun menu autorisé
                  </div>
                </div>
              </div>
            </v-expand-transition>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogs -->
    <v-dialog v-model="dialog" max-width="50%">
      <rights-create
        :selected-user="selectedUser"
        @close-modal="closeModal"
        @rightCreated="updateRightsList"
      ></rights-create>
    </v-dialog>

    <v-dialog v-model="dialogCreateTeam" max-width="50%">
      <team-create
        v-if="dialogCreateTeam"
        :selected-user="selectedUser"
        @close-modal="closeTeamModal"
        @roles-updated="updateRightsList"
      ></team-create>
    </v-dialog>

    <v-dialog v-model="menuRightsDialog" max-width="900px">
      <v-card v-if="droit.length > 0" class="rights-dialog">
        <v-card-title class="primary white--text py-4 text-h5">
          Gestion des droits d'accès
          <v-spacer></v-spacer>
          <v-btn icon dark @click="menuRightsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card class="pa-4">
          <v-tabs
            v-model="tab"
            background-color="primary"
            dark
            show-arrows
            slider-size="3"
            class="rights-tabs mt-4"
          >
            <v-tab
              v-for="(user, userIndex) in droit"
              :key="userIndex"
              :href="'#tab-' + user.droit.tag"
              class="text-capitalize font-weight-medium"
            >
              <v-icon left small>{{ getRoleIcon(user.droit.tag) }}</v-icon>
              {{ user.droit.tag }}
            </v-tab>
          </v-tabs>

          <v-divider></v-divider>

          <v-tabs-items v-model="tab" class="rights-content">
            <v-tab-item
              v-for="(user, userIndex) in droit"
              :key="userIndex"
              :value="'tab-' + user.droit.tag"
            >
              <v-card flat class="pa-4">
                <v-row>
                  <!-- Section Utilisateurs -->
                  <v-col cols="12" md="5">
                    <v-card outlined class="rounded-lg">
                      <v-card-title class="subtitle-1 py-2 grey lighten-4">
                        <v-icon left color="primary">mdi-account-group</v-icon>
                        Utilisateurs
                        <v-chip
                          class="ml-2"
                          x-small
                          :color="getRoleColor(user.droit.tag)"
                          text-color="white"
                        >
                          {{
                            user.droit.users.filter((userId) => usersName[userId])
                              .length
                          }}
                        </v-chip>
                      </v-card-title>

                      <v-card-text
                        class="pt-4"
                        style="max-height: 350px; overflow-y: auto;"
                      >
                        <v-list dense v-if="user.droit.users.length > 0">
                          <template v-for="(userId, index) in user.droit.users">
                            <!-- N'afficher que si l'utilisateur existe dans usersName -->
                            <v-list-item
                              v-if="usersName[userId]"
                              :key="index"
                              class="user-item"
                            >
                              <v-list-item-avatar size="32">
                                <v-avatar color="primary lighten-3" size="32">
                                  <span class="white--text caption">
                                    {{ getInitialsModal(usersName[userId]) }}
                                  </span>
                                </v-avatar>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="subtitle-2">
                                  {{ usersName[userId] }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-list>
                        <div v-else class="text-center pa-4 grey--text">
                          <v-icon large color="grey lighten-1"
                            >mdi-account-off</v-icon
                          >
                          <div class="mt-2">Aucun utilisateur</div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>

                  <!-- Section Menus -->
                  <v-col cols="12" md="7">
                    <v-card outlined class="rounded-lg">
                      <v-card-title class="subtitle-1 py-2 grey lighten-4">
                        <v-icon left color="primary">mdi-telescope</v-icon>
                        Menus autorisés
                        <v-chip
                          class="ml-2"
                          x-small
                          :color="getRoleColor(user.droit.tag)"
                          text-color="white"
                        >
                          {{ user.droit.menus.length }}
                        </v-chip>
                      </v-card-title>

                      <v-card-text class="pt-4">
                        <div v-if="user.droit.menus.length > 0" class="menu-grid">
                          <v-chip
                            v-for="(menu, index) in user.droit.menus"
                            :key="index"
                            class="ma-1"
                            outlined
                            small
                          >
                            <v-icon left small>mdi-link</v-icon>
                            {{ menu }}
                          </v-chip>
                        </div>
                        <div v-else class="text-center pa-4 grey--text">
                          <v-icon large color="grey lighten-1"
                            >mdi-link-off</v-icon
                          >
                          <div class="mt-2">Aucun menu autorisé</div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <!-- Actions -->
                <v-row v-if="isSuperAdmin" class="mt-4">
                  <v-col cols="12" class="text-right">
                    <v-btn
                      color="primary"
                      outlined
                      class="mr-2"
                      @click="openModalEdit(user.droit.tag)"
                    >
                      <v-icon left small>mdi-pencil</v-icon>
                      Modifier
                    </v-btn>
                    <v-btn
                      color="error"
                      outlined
                      @click="openModalDelete(user.droit.tag)"
                    >
                      <v-icon left small>mdi-delete</v-icon>
                      Supprimer
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" max-width="50%">
      <rights-edit
        @close-edit-modal="dialogEdit = false"
        @rightEdited="updateRightsList"
      ></rights-edit>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="30%">
      <rights-delete
        @close-delete-modal="dialogDelete = false"
        @rightDeleted="updateRightsList"
      ></rights-delete>
    </v-dialog>

    <v-dialog v-model="dialogParametrage" max-width="50%">
      <bases-parametrage
        @close-modal="dialogParametrage = false"
      ></bases-parametrage>
    </v-dialog>

    <v-dialog v-model="dialogListParametrage" max-width="80%">
      <listes-parametrage
        @close-modal="dialogListParametrage = false"
      ></listes-parametrage>
    </v-dialog>
    <!-- modale de liste de teamrole -->
    <v-dialog v-model="listeTeamRoleModal" max-width="50%">
      <liste-team-role @close="listeTeamRoleModal = false" />
    </v-dialog>

    <v-dialog v-model="dialogEditTeam" max-width="800">
      <team-role-card
        v-if="dialogEditTeam && selectedUser && currentTeamRole"
        :team-role="currentTeamRole"
        :user-name="fullName"
        @updated="handleEdit"
        @close="dialogEditTeam = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import UserService from "@/Services/UserService";
import DroitService from "@/Services/SupportVision/DroitService";
import BasesService from "@/Services/SupportVision/BasesService";
import rightsCreate from "./rightsCreate.vue";
import rightsEdit from "./rightsEdit.vue";
import rightsDelete from "./rightsDelete.vue";
import basesParametrage from "@/Components/Views/SupportVision/Bases/basesParametrage.vue";
import listesParametrage from "@/Components/Views/SupportVision/Bases/listesParametrage.vue";
import teamAndRoleCreate from "./teamAndRoleCreate.vue";
import teamRoleCard from "./teamRoleCard.vue";

export default {
  name: "UserRightsGrid",

  components: {
    "rights-create": rightsCreate,
    "rights-edit": rightsEdit,
    "rights-delete": rightsDelete,
    "bases-parametrage": basesParametrage,
    "listes-parametrage": listesParametrage,
    "team-create": teamAndRoleCreate,
    "team-role-card": teamRoleCard,
    "liste-team-role": () =>
      import("@/Components/Views/SupportVision/Droits/listeTeamRole.vue"),
  },

  data() {
    return {
      search: "",
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogParametrage: false,
      dialogListParametrage: false,
      dialogEditTeam: false,
      users: [],
      droit: [],
      menusInterface: [],
      selectedUser: null,
      dialogCreateTeam: false,
      menuRightsDialog: false,
      listeTeamRoleModal: false,
      tab: null,
      teamRoles: [],
      loading: false,
      userTeamRole: null,
    };
  },

  computed: {
    ...mapState(["droitSupportVision", "informations", "basesParams"]),
    usersName() {
      return this.users.reduce((acc, user) => {
        acc[user.id] = user.firstname + " " + user.lastname;
        return acc;
      }, {});
    },

    droitSupportVisionArray() {
      return Array.isArray(this.droitSupportVision)
        ? this.droitSupportVision
        : [];
    },

    isSuperAdmin() {
      return (
        this.droitSupportVisionArray.some(
          (user) =>
            user.droit?.tag === "SUPERADMIN" && user.base === this.getDns()
        ) &&
        this.droitSupportVisionArray.some((userId) =>
          userId.droit?.users?.includes(this.informations.id)
        )
      );
    },

    filteredUsers() {
      if (!this.search) return this.users;
      const searchTerm = this.normalizeString(this.search);
      return this.users.filter((user) =>
        this.normalizeString(`${user.firstname} ${user.lastname}`).includes(
          searchTerm
        )
      );
    },
    // triér par droit admin en premier et les autres ensuite et les sans droits en dernier
    sortedDroit() {
      // Créer une copie du tableau pour éviter les effets de bord
      return [...this.filteredUsers].sort((a, b) => {
        const rightsA = this.getUserRights(a.id)[0] || "";
        const rightsB = this.getUserRights(b.id)[0] || "";

        // SUPERADMIN en premier
        if (rightsA === "SUPERADMIN") return -1;
        if (rightsB === "SUPERADMIN") return 1;

        // Utilisateurs sans droits en dernier
        if (!rightsA && rightsB) return 1;
        if (rightsA && !rightsB) return -1;

        // Pour les autres droits, garder l'ordre alphabétique des noms
        const nameA = `${a.firstname} ${a.lastname}`.toLowerCase();
        const nameB = `${b.firstname} ${b.lastname}`.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    },
    currentTeamRole() {
      const userTeamRole = this.teamRoles.find(
        (tr) =>
          tr.teamRole && tr.teamRole.userId === this.selectedUser?.id.toString()
      );
      // S'assurer de retourner l'objet complet avec _id
      return userTeamRole
        ? {
            _id: userTeamRole._id, // Important pour l'édition
            ...userTeamRole.teamRole,
          }
        : null;
    },
    fullName() {
      return this.selectedUser
        ? `${this.selectedUser.firstname} ${this.selectedUser.lastname}`
        : "";
    },
  },

  methods: {
    ...mapMutations([
      "setDroitSupportVision",
      "setItemsMenu",
      "setUsersGtp",
      "setDroitTag",
    ]),

    handleEdit(teamRole) {
      // this.openModalCreateTeam(this.selectedUser);
    },

    handleDeleted() {
      this.fetchTeamRoles();
    },
    getRoleIcon(role) {
      const icons = {
        SUPERADMIN: "mdi-shield-crown",
        ADMIN: "mdi-shield-check",
        USER: "mdi-account",
      };
      return icons[role] || "mdi-account";
    },

    getRoleColor(role) {
      const colors = {
        SUPERADMIN: "red darken-1",
        ADMIN: "orange darken-2",
        USER: "green darken-1",
      };
      return colors[role] || "grey";
    },

    getInitialsModal(name) {
      if (!name || typeof name !== "string") return "";

      return name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    },

    openRightsMenu() {
      this.menuRightsDialog = true;
    },

    getInitials(firstname, lastname) {
      return `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase();
    },

    hasUserRights(userId) {
      return this.getUserRights(userId).length > 0;
    },

    getUserRights(userId) {
      if (!Array.isArray(this.droitSupportVision)) return [];

      const userRights = this.droitSupportVision.find((right) =>
        right.droit?.users?.includes(userId.toString())
      );
      return userRights ? [userRights.droit.tag] : [];
    },

    getUserMenus(userId) {
      if (!Array.isArray(this.droitSupportVision)) return [];

      const userRights = this.droitSupportVision.find((right) =>
        right.droit?.users?.includes(userId.toString())
      );
      return userRights?.droit?.menus || [];
    },

    getUserTeam(userId) {
      const email = this.users.find(
        (u) => u.id.toString() === userId.toString()
      )?.emailAddress;

      if (!email || !this.basesParams?.[0]?.params) return "Non assigné";

      const params = this.basesParams[0].params;

      if (params.directionMail.includes(email)) return "Direction";
      if (params.sysAdminMail.includes(email)) return "SysAdmin";
      if (params.callCenterMail.includes(email)) return "Call Center";
      if (params.equipeTechniqueMail.includes(email)) return "Équipe Technique";

      return "Non assigné";
    },

    getRightColor(right) {
      const colors = {
        SUPERADMIN: "red darken-1",
        ADMIN: "orange darken-2",
        USER: "green darken-1",
      };
      return colors[right] || "primary";
    },

    normalizeString(str) {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },

    toggleMenus(user) {
      this.$set(user, "showMenus", !user.showMenus);
    },

    openCreateModalForUser(user) {
      this.selectedUser = user;
      this.dialog = true;
    },

    openModal() {
      if (!this.isSuperAdmin) {
        this.dialog = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.selectedUser = null;
        this.dialog = true;
      }
    },

    closeModal() {
      this.dialog = false;
      this.selectedUser = null;
    },

    openModalEdit(tag) {
      if (tag) {
        this.$store.commit("setDroitTag", tag);
        this.dialogEdit = true;
      }
    },

    openModalDelete(tag) {
      this.$store.commit("setDroitTag", tag);
      this.dialogDelete = true;
    },

    openModalParametrage() {
      if (!this.isSuperAdmin) {
        this.dialogParametrage = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.dialogParametrage = true;
      }
    },

    openModalListParametrage() {
      if (!this.isSuperAdmin) {
        this.dialogListParametrage = false;
        this.$nError("Vous n'avez pas les accès.");
      } else {
        this.dialogListParametrage = true;
      }
    },

    async updateRightsList() {
      try {
        const res = await DroitService.getDroit();
        this.$store.commit("setDroitSupportVision", res);
        this.droit = this.droitSupportVision;
        this.selectedUser = null;
      } catch (error) {
        console.error(error);
        this.$nError("Erreur lors de la mise à jour des droits");
      }
    },

    async getMenusInterface() {
      try {
        const res = await DroitService.getMenuInterface();
        this.menusInterface = res.menusInterface.map((item) => item.title);
        this.$store.commit("setItemsMenu", this.menusInterface);
      } catch (error) {
        console.error(error);
        this.$nError("Erreur lors du chargement des menus");
      }
    },

    async fetchTeamRoles() {
      try {
        this.loading = true;
        const response = await DroitService.getTeamAndRole();
        this.teamRoles = Array.isArray(response.data) ? response.data : [];
      } catch (error) {
        console.error(error);
        this.$nError("Erreur lors du chargement des équipes et rôles");
      } finally {
        this.loading = false;
      }
    },

    // Dans la section methods
    getUserTeamFromRoles(userId) {
      const userTeamRole = this.teamRoles.find(
        (tr) => tr.teamRole?.userId === userId.toString()
      );
      return userTeamRole?.teamRole?.team || "Non assigné";
    },

    // gestion des équipes
    async openModalCreateTeam(user) {
      this.selectedUser = user;
      this.dialogCreateTeam = true;
    },

    closeTeamModal() {
      this.dialogCreateTeam = false;
      this.selectedUser = null;
    },
    async openModalDeleteTeam(user) {
      this.selectedUser = user;
      this.dialogEditTeam = true; // Ajoutez dialogEditTeam dans data()
    },
  },

  async mounted() {
    try {
      const users = await UserService.getUsers();
      // Ajouter la propriété showMenus à chaque utilisateur
      users.forEach((user) => {
        this.$set(user, "showMenus", false);
      });
      this.users = users;
      this.$store.commit("setUsersGtp", users);

      const droitRes = await DroitService.getDroit();
      if (droitRes === "No rights found") {
        this.$store.commit("setDroitSupportVision", []);
      } else {
        this.$store.commit("setDroitSupportVision", droitRes);
      }
      this.droit = this.droitSupportVision;

      const params = await BasesService.getParams();
      this.$store.commit("setBasesParams", params);

      await this.fetchTeamRoles();

      await this.getMenusInterface();
    } catch (error) {
      console.error(error);
      this.$nError("Erreur lors du chargement des données");
    }
  },

  watch: {
    droitSupportVision: {
      handler() {
        this.droit = this.droitSupportVision;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.user-card {
  position: relative;
  transition: all 0.3s ease;
  height: 100%;
}

.user-card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 180px;
}

.user-info {
  flex: 10;
  min-width: 0; /* Pour gérer le texte trop long */
}

.user-info .text-subtitle-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menu-section {
  margin-top: auto;
}

.create-btn {
  height: 24px !important;
  min-width: 80px !important;
}

.edit-btn {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.no-rights-text,
.no-menus-text {
  color: #999;
  font-size: 0.75rem;
  font-style: italic;
  padding: 4px 0;
}

.menus-container {
  max-height: 150px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.02);
  margin-top: 4px;
  transition: all 0.3s ease;
}

.menus-container::-webkit-scrollbar {
  width: 4px;
}

.menus-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.menus-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.menus-container::-webkit-scrollbar-thumb:hover {
  background: #666;
}

.v-btn.v-btn--text {
  text-transform: none;
  letter-spacing: normal;
}

/* Animation pour l'expansion */
.v-expand-transition-enter-active,
.v-expand-transition-leave-active {
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.v-expand-transition-enter,
.v-expand-transition-leave-to {
  opacity: 0;
  height: 0;
}

/* Style pour les puces */
.v-chip.v-size--x-small {
  height: 20px !important;
}

/* Ajustement pour le conteneur de droits */
.rights-container {
  min-height: 30px;
  display: flex;
  align-items: center;
}

/* Style pour le bouton d'expansion */
.menu-section .v-btn {
  opacity: 0.8;
}

.menu-section .v-btn:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.03);
}

/* Animation pour le hover des cartes */
.user-card {
  transform-origin: center;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.user-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15) !important;
}

/* Style pour les états de chargement et d'erreur */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.error-state {
  color: #ff5252;
  font-size: 0.875rem;
  margin-top: 4px;
}

/* Ajouter dans la section <style scoped> */
.v-list-item {
  min-height: 35px !important;
}

.v-list-item__icon {
  margin: 4px 8px 4px 0 !important;
}

.subtitle-2 {
  font-size: 0.875rem !important;
}

/* Hover effect pour les items de la liste */
.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  opacity: 0.85;
  transition: opacity 0.2s ease;
}

.v-list-item:hover {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .user-card-content {
    padding: 12px !important;
  }

  .create-btn {
    min-width: 60px !important;
  }

  .v-avatar {
    size: 32px !important;
  }
}
</style>
