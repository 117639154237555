<template>
  <v-card class="role-card">
    <v-toolbar flat color="primary" dark>
      <v-toolbar-title class="text-h5">
        Attribution des droits pour {{ selectedUserName }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-select
          v-model="userData.team"
          :items="teamSelection"
          label="Équipe"
          prepend-icon="mdi-account-group"
          :rules="[v => !!v || 'Veuillez sélectionner une équipe']"
          class="mb-6"
        ></v-select>

        <v-expansion-panels>
          <!-- Planning Panel -->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-1">
              <div class="d-flex align-center">
                <v-icon left color="primary">mdi-calendar</v-icon>
                Plannification
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <!-- Scope des droits de planification -->
                <v-subheader>Périmètre de visualisation/planification</v-subheader>
                <v-radio-group v-model="userData.roles.planning.scope">
                  <v-radio label="Voir et planifier tout le monde" value="all"></v-radio>
                  <v-radio label="Voir et planifier par équipe" value="team"></v-radio>
                  <v-radio label="Voir et planifier son planning uniquement" value="self"></v-radio>
                </v-radio-group>

                <v-divider class="my-3"></v-divider>

                <!-- Droits de drag & drop -->
                <v-subheader>Déplacement des tâches (Drag & Drop)</v-subheader>
                <v-radio-group v-model="userData.roles.planning.dragDrop">
                  <v-radio label="Déplacer toutes les tâches" value="all"></v-radio>
                  <v-radio label="Déplacer les tâches de l'équipe" value="team"></v-radio>
                  <v-radio label="Déplacer ses propres tâches uniquement" value="self"></v-radio>
                </v-radio-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- Cartes Panel -->
          <v-expansion-panel>
            <v-expansion-panel-header class="py-1">
              <div class="d-flex align-center">
                <v-icon left color="success">mdi-check-circle</v-icon>
                Gestion des tâches
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.create"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Créer des tâches</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.viewAllTeams"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Voir les tâches de toutes les équipes</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- voir les tache de son equipe uniquement -->
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.viewTeamTasks"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Voir les tâches de son équipe</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.writeOwnTasks"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Modifier ses propres tâches</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.writeTeamTasks"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Modifier les tâches de l'équipe</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox v-model="userData.roles.tasks.editOthersTasks"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Modifier les tâches des autres équipes</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-form>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn text @click="closeModal">Annuler</v-btn>
      <v-btn color="primary" :loading="loading" :disabled="!valid" @click="saveUserRoles">
        Enregistrer
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DroitService from "@/Services/SupportVision/DroitService";

export default {
  name: 'teamAndRoleCreate',

  props: {
    selectedUser: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      valid: false,
      loading: false,
      teamSelection: ['Encadrement', 'Projet', 'Développeur'],
      userData: {
        userId: null,
        team: null,
        roles: {
          planning: {
            scope: 'self',
            dragDrop: 'self',
            viewTeamPlanning: false,
            writeTeamPlanning: false,
            writeOwnPlanning: false
          },
          tasks: {
            create: false,
            editOthersTasks: false,
            viewTeamTasks: false,
            writeOwnTasks: false,
            viewAllTeams: false,
            writeTeamTasks: false
          }
        }
      }
    }
  },

  computed: {
    selectedUserName() {
      return `${this.selectedUser.firstname} ${this.selectedUser.lastname}`;
    }
  },

  methods: {
    closeModal() {
      this.$emit('close-modal');
    },

    async saveUserRoles() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;
      try {
        const payload = {
          userId: this.selectedUser.id,
          ...this.userData
        };
        
        await DroitService.saveTeamAndRole(payload);
        this.$nSuccess('Droits enregistrés avec succès');
        this.$emit('roles-updated');
        this.closeModal();
      } catch (error) {
        console.error('Erreur lors de l\'enregistrement:', error);
        this.$nError('Erreur lors de l\'enregistrement');
      } finally {
        this.loading = false;
      }
    },

    initializeUserData() {
      this.userData.userId = this.selectedUser.id;
      if (this.selectedUser.roles) {
        this.userData = {
          ...this.userData,
          ...this.selectedUser.roles
        };
      }
    }
  },

  created() {
    this.initializeUserData();
  }
}
</script>

<style scoped>
.role-card {
  border-radius: 4px;
}

.v-expansion-panel-header {
  padding: 12px 16px;
}

.v-expansion-panel-content__wrap {
  padding: 0;
}

.v-list-item {
  min-height: 40px;
}

.v-subheader {
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 16px;
  height: 32px;
}
</style>